@import url(https://fonts.googleapis.com/css?family=Raleway:300,400);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,900);
@keyframes rl-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes rl-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.rl-spinner {
  position: relative;
  display: inline-block;
  width: var(--rl-spinner-size) !important;
  height: var(--rl-spinner-size) !important;
  -webkit-transform: translate(
      calc(var(--rl-spinner-translate) * -1),
      calc(var(--rl-spinner-translate) * -1)
    )
    scale(var(--rl-spinner-scale))
    translate(var(--rl-spinner-translate), var(--rl-spinner-translate));
  transform: translate(
      calc(var(--rl-spinner-translate) * -1),
      calc(var(--rl-spinner-translate) * -1)
    )
    scale(var(--rl-spinner-scale))
    translate(var(--rl-spinner-translate), var(--rl-spinner-translate));
}
.rl-spinner div {
  left: 94px;
  top: 48px;
  position: absolute;
  -webkit-animation: rl-spinner linear var(--rl-spinner-speed) infinite;
  animation: rl-spinner linear var(--rl-spinner-speed) infinite;
  background: var(--rl-spinner-color);
  width: 12px;
  height: 24px;
  border-radius: 40%;
  -webkit-transform-origin: 6px 52px;
  transform-origin: 6px 52px;
}
.rl-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -0.916666666666667s;
  animation-delay: -0.916666666666667s;
}
.rl-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -0.833333333333333s;
  animation-delay: -0.833333333333333s;
}
.rl-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}
.rl-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.666666666666667s;
  animation-delay: -0.666666666666667s;
}
.rl-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.583333333333333s;
  animation-delay: -0.583333333333333s;
}
.rl-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.rl-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.416666666666667s;
  animation-delay: -0.416666666666667s;
}
.rl-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.333333333333333s;
  animation-delay: -0.333333333333333s;
}
.rl-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.rl-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.166666666666667s;
  animation-delay: -0.166666666666667s;
}
.rl-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.083333333333333s;
  animation-delay: -0.083333333333333s;
}
.rl-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.btn {
  display: inline-block;
  border: none;
  border-radius: .3em;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 -0.25em 0 rgba(0, 0, 0, 0.25), 0 0.25em 0.25em rgba(0, 0, 0, 0.05);
  color: #fff;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 1px;
  padding: .5em 1.5em .75em;
  position: relative;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.25);
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .btn:active {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 2px 0 rgba(255, 255, 255, 0.1), inset 0 0.25em 0.5em rgba(0, 0, 0, 0.05);
    margin-top: .25em;
    padding-bottom: .5em; }
  .btn:active, .btn:focus {
    outline: none; }

.btn--blue {
  background-color: #3498db; }

.btn--green {
  background-color: #1abc9c; }

.btn--red {
  background-color: #e65d4f; }

.btn--lg {
  font-size: 1.5em; }

.btn--df {
  font-size: 1.25em; }

.btn--md {
  font-size: 1em; }

.btn--sm {
  font-size: 0.75em; }

